import { Layout,FloatButton } from "antd";
import { Route, Routes } from "react-router-dom";
import { Home } from "../Home";
import { Navbar } from "../Navbar";
import styles from "./Styles/App.module.css";
import { FooterComp } from "../Footer/FooterComp";
import { UpOutlined } from "@ant-design/icons";

const {Content } = Layout;

function App() {
  const customIcon = <UpOutlined className={styles.backToTopIcon} />;
  return (
    <Layout className={styles.layoutStyle}>
      <Content className={styles.layoutStyle}>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </Content>
      <div className={styles.backto}>
        <FloatButton.BackTop
          shape="circle"
          icon={customIcon}
          rootClassName={styles.backtotop}
          style={{ color:"white" }}
        ></FloatButton.BackTop>
      </div>
      <FooterComp/>
    </Layout>
  );
}

export { App };
