import React from "react";
import Slider from "react-slick";
// import { useMediaQuery } from "react-responsive";
import Slide from "./Slide";
import { Layout, Row } from "antd";
// import ourProvidingsMobile from "../../Assets/our_providings/ourServices.png";
import ourProvidingsBackground from "../../Assets/our_providings/providings.png";
import carouselImage1 from "../../Assets/our_providings/C1.png";
import carouselImage2 from "../../Assets/our_providings/C2.png";
import carouselImage3 from "../../Assets/our_providings/C3.png";
import carouselImage4 from "../../Assets/our_providings/C4.png";
import carouselImage5 from "../../Assets/our_providings/C5.png";
import carouselImage6 from "../../Assets/our_providings/C6.png";
import carouselImage7 from "../../Assets/our_providings/C7.png";
import carouselImage8 from "../../Assets/our_providings/C8.png";
import carouselImage9 from "../../Assets/our_providings/C9.png";
import carouselImage10 from "../../Assets/our_providings/C10.png";
import carouselImage11 from "../../Assets/our_providings/C11.png";
import carouselImage12 from "../../Assets/our_providings/C12.png";
import petBoarding from "../../Assets/our_providings/boarding.png";
import cremation from "../../Assets/our_providings/petCremation.png";
import pickupdrop from "../../Assets/our_providings/petPickups.png";
import daycare from "../../Assets/our_providings/petdaycare.png";
import hygenicfoods from "../../Assets/our_providings/hygenicfoods.png";
import petsitting from "../../Assets/our_providings/petSitting.png";
import petAdoption from "../../Assets/our_providings/petAdoption.png";
import petTraining from "../../Assets/our_providings/training.png";
import petWalking from "../../Assets/our_providings/petWalking.png";
import spa from "../../Assets/our_providings/spa&grooming.png";
import fashion from "../../Assets/our_providings/fashion.png";
import boardingName from "../../Assets/our_providings/petBoardingName.png";
import cremationName from "../../Assets/our_providings/PetCremationName.png";
import pickName from "../../Assets/our_providings/Pickup&dropName.png";
import daycareName from "../../Assets/our_providings/DayCareName.png";
import hygenicName from "../../Assets/our_providings/HygenicFoodsName.png";
import adoptionName from "../../Assets/our_providings/PetAdoptionNaME.png";
import sittingName from "../../Assets/our_providings/SittingName.png";
import trainingName from "../../Assets/our_providings/PetTraining.png";
import walkingName from "../../Assets/our_providings/PetWalkingName.png";
import spaName from "../../Assets/our_providings/Spa&GroomingName.png";
import fashionName from "../../Assets/our_providings/PetFashionName.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../Styles/ourProvidings.module.css";

const { Content } = Layout;

const CarouselData = [
  {
    title: "Pet boarding",
    image: carouselImage1,
    description:
      "Temporary care for pets in a dedicated facility while owners are away.",
    petImage: petBoarding,
    serviceDesc:
      "Welcome to our premium pet boarding service, where your furry friends are treated like family. Our state-of-the-art facilities ensure a comfortable stay, complete with personalized care, spacious accommodations, and regular playtime. Trust us to provide a home away from home for your pets, where their well-being and happiness are our top priorities.",
    serviceName: boardingName,
  },
  {
    title: "Pet pickup & drops",
    image: carouselImage2,
    description:
      "Transportation service for pets pickup and drop-off at desired locations.",
    petImage: pickupdrop,
    serviceDesc:
      "Welcome to our premium pet pick up & drop service, Experience hassle-free pet transportation with our reliable pick-up and drop service. Our trained professionals ensure your pet's comfort and safety throughout the journey. From door to door, we provide a seamless and stress-free experience for both you & your beloved furry friend. Trust us for convenient and caring pet transport services.",
    serviceName: pickName,
  },
  {
    title: "Hygienic foods",
    image: carouselImage3,
    description: "Health with clean, nutritious & safe food choices",
    petImage: hygenicfoods,
    serviceDesc:
      "Serving hygienic food in our pet care, we prioritize your furry friend's well-being by serving hygienic and nutritious meals. Our dedicated team ensures that every pet receives wholesome, carefully curated food to support their health and happiness. Trust us to provide a clean and safe dining experience for your beloved pets.",
    serviceName: hygenicName,
  },
  {
    title: "Pet adoption",
    image: carouselImage4,
    description: "Finding homes for pets through adoption processes.",
    petImage: petAdoption,
    serviceDesc:
      "Welcome to our pet adoption service! Discover furry companions ready to fill your home with love. Our diverse selection includes dogs & cats, each seeking a forever home. Experience the joy of adoption with us—where tails wag and purrs abound. Find your perfect match and make a difference in a pet's life today!",
    serviceName: adoptionName,
  },
  {
    title: "Pet walkings",
    image: carouselImage5,
    description: "Professional service for walking & caring for pets outdoors.",
    petImage: petWalking,
    serviceDesc:
      "Experience unparalleled care with Our Pet Walking Service! Our team of dedicated professionals ensures your furry friend receives the love and exercise they deserve. Tailored walks, personalized attention, and a commitment to your pet's well-being. Trust us to keep their tails wagging with joy. Book a walk today for happiness unleashed!",
    serviceName: walkingName,
  },
  {
    title: "Pet cremation",
    image: carouselImage6,
    description:
      "Cremating deceased pets, providing ashes in memorial containers for owners.",
    petImage: cremation,
    serviceDesc:
      "Our compassionate pet cremation service Provides a dignified farewell to beloved companions.With utmost care and respect, we offer individual cremations,ensuring each pet receives personalized attention. We understand the profound bond between pets and their owners, offering solace through a thoughtful and respectful farewell for cherished furry family members.",
    serviceName: cremationName,
  },
  {
    title: "Pet day care",
    image: carouselImage7,
    description:
      "Professional supervision & care for pets in a secure environment.",
    petImage: daycare,
    serviceDesc:
      "At Our Pet Day Care, we provide a loving and secure environment for your furry friends. Our experienced staff offers personalized attention, engaging activities, and a safe space for play. Whether it's daily care or overnight stays, trust us to pamper and care for your pets like family. Happy, healthy pets, guaranteed!",
    serviceName: daycareName,
  },
  {
    title: "Pet sitting",
    image: carouselImage8,
    description: "Caring for pets in their homes during owners' absence.",
    petImage: petsitting,
    serviceDesc:
      "Discover peace of mind with our premier pet sitting service. Our experienced and caring team ensures your furry friends receive top-notch care in the comfort of their own home. From daily walks to personalized attention, we go the extra mile to keep tails wagging and whiskers purring. Trust us with your pets!",
    serviceName: sittingName,
  },
  {
    title: "Pet training",
    image: carouselImage9,
    description:
      "Professional guidance to teach & discipline pets for desired behavior.",
    petImage: petTraining,
    serviceDesc:
      "Transform your furry friend into the perfect companion with our expert pet training service. Our certified trainers use positive reinforcement methods to address behavior issues and enhance obedience. From basic commands to advanced skills, we tailor our approach to suit your pet's needs. Experience the joy of a well-behaved and happy pet with us!",
    serviceName: trainingName,
  },
  {
    title: "Pet SPA & grooming",
    image: carouselImage10,
    description:
      "Professional care & maintenance of pets hygiene & appearance.",
    petImage: spa,
    serviceDesc:
      "Indulge your furry friends in the ultimate pampering experience at our Pet SPA & Grooming Service. Our skilled professionals provide top-notch grooming services, ensuring your pets look and feel their best. Treat them to a day of relaxation, luxury baths, and expert grooming for a radiant and happy pet.",
    serviceName: spaName,
  },
  {
    title: "Pet fashion show",
    image: carouselImage11,
    description:
      "Event showcasing stylish clothing & accessories for pets in a runway.",
    petImage: fashion,
    serviceDesc:
      "Step into the world of glamour with our Pet Fashion Show service! Treat your furry friends to a runway experience like no other. From stylish costumes to adorable accessories, we ensure your pets steal the spotlight. Let them strut their stuff in a showcase of cuteness and charm, leaving tails wagging!",
    serviceName: fashionName,
  },
  {
    title: "Pet maternity",
    image: carouselImage12,
    description:
      "Professional pet maternity care for expecting furry family members.",
    petImage: fashion,
    serviceDesc:
      "Step into the world of glamour with our Pet Fashion Show service! Treat your furry friends to a runway experience like no other. From stylish costumes to adorable accessories, we ensure your pets steal the spotlight. Let them strut their stuff in a showcase of cuteness and charm, leaving tails wagging!",
    serviceName: fashionName,
  },
];

const OurProvidings = () => {
  // const isMobile = useMediaQuery({ maxWidth: 768 });

  const CustomPrevArrow = ({
    currentSlide,
    slideCount,
    children,
    ...props
  }) => (
    <div
      {...props}
      style={{
        position: "absolute",
        left: "8px",
        transform: "translateY(-50%)",
        zIndex: 1,
        background: "#000",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      &lt;
    </div>
  );

  const CustomNextArrow = ({
    currentSlide,
    slideCount,
    children,
    ...props
  }) => (
    <div
      {...props}
      style={{
        position: "absolute",
        right: "8px",
        transform: "translateY(-50%)",
        zIndex: 1,
        background: "#000",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      &gt;
    </div>
  );

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    autoplay: false,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    focusOnChange: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    useCSS: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: true,
          fade: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  // const settings2 = {
  //   dots: false,
  //   arrows: true,
  //   infinite: true,
  //   speed: 300,
  //   slidesToShow: 2,
  //   autoplay: true,
  //   slidesToScroll: 1,
  //   autoplaySpeed: 2000,
  //   focusOnChange: true,
  //   prevArrow: <CustomPrevArrow />,
  //   nextArrow: <CustomNextArrow />,
  //   useCSS: true,

  //   responsive: [
  //     {
  //       breakpoint: 576,
  //       settings: {
  //         arrows: true,
  //         fade: true,
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <Content id="services" className={styles.providingsContent}>
      <Row style={{ position: "relative" }}>
        <img
          src={ourProvidingsBackground}
          alt="background_image"
          className={styles.carouselImage}
          // style={{ display: isMobile ? "none" : "initial" }}
        />
        {/* <img
          src={ourProvidingsMobile}
          alt="background_image"
          className={styles.carouselImage}
          style={{ display: isMobile ? "initial" : "none" }}
        /> */}

        <Slider
          {...settings}
          // style={{
          //   display: isMobile ? "none" : "initial",
          // }}
          className={styles.slider}
        >
          {CarouselData?.map((service) => (
            <Slide
              key={service?.title}
              serviceCard={"serviceCard"}
              serviceImage={"serviceImage"}
              serviceTitle={"serviceTitle"}
              serviceDescription={"serviceDescription"}
              btn={"btn"}
              service={service}
            />
          ))}
        </Slider>
      </Row>
      {/* <div
        style={{
          display: isMobile ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Slider
          {...settings2}
          style={{
            margin: "2%",
            padding: "15px",
          }}
          className={styles.slider1}
        >
          {CarouselData?.slice(0, 6)?.map((service) => (
            <Slide serviceCard={"serviceCard1"} serviceImage={"serviceImage1"} serviceTitle={"serviceTitle1"} serviceDescription={"serviceDescription1"} btn={"btn1"} key={service?.title} service={service} />
          ))}
        </Slider>
        <Slider
          {...settings2}
          style={{
            margin: "2%",
            padding: "15px",
          }}
          className={styles.slider2}
        >
          {CarouselData?.slice(6)?.map((service) => (
            <Slide serviceCard={"serviceCard2"} serviceImage={"serviceImage2"} serviceTitle={"serviceTitle2"} serviceDescription={"serviceDescription2"} btn={"btn2"} key={service?.title} service={service} />
          ))}
        </Slider>
      </div> */}
    </Content>
  );
};

export default OurProvidings;
