import {
  applyMiddleware,
  legacy_createStore as createStore,
  combineReducers,
} from "redux";
import { thunk } from "redux-thunk";
import { appReducer } from "./state/index";
import {navbarReducer} from "./Navbar"

const reducers = { appReducer, navbarReducer};

const rootReducers = combineReducers(reducers);
const store = applyMiddleware(thunk)(createStore)(rootReducers);

export { store, reducers };
