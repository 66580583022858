// import React from "react";
// import { Col, Image, Layout, Row } from "antd";
// import { useMediaQuery } from "react-responsive";
// import { Link } from "react-router-dom";
// import welcomeBanner from "../../Assets/Welcome_Banner/banner.png";
// import welcomeBannerMobile from "../../Assets/Welcome_Banner/mobileBanner.png";
// import signIn from "../../Assets/Welcome_Banner/SignInButtton.png";
// import demo from "../../Assets/Welcome_Banner/watchDemoButton.png";
// import styles from "../Styles/welcomeBanner.module.css";
// import SignInWatchDemoButton from "../../Common/Components/SignInWatchDemoButton";

// const { Content } = Layout;

// const WelcomeBanner = () => {
//   const isMobile = useMediaQuery({ maxWidth: 768 });
//   return (
//     <Content className={styles.bannerContent}>
//       <Row className={styles.bannerRow}>
//         <Col span={24} style={{ display: isMobile ? "none" : "initial" }}>
//           <img
//             src={welcomeBanner}
//             alt="Welcome Banner"
//             className={styles.bannerImage}
//           />
//         </Col>
//         <Col
//           md={20}
//           style={{
//             display: isMobile ? "initial" : "none",
//             width: isMobile ? "88%" : "0",
//             margin: "0 auto",
//           }}
//         >
//           <Image
//             preview={false}
//             src={welcomeBannerMobile}
//             alt="Welcome Banner"
//             className={styles.bannerImageMobile}
//           />
//         </Col>
//         {isMobile ? <SignInWatchDemoButton /> : ""}
//         <div
//           className={styles.imageWrapper}
//           style={{ display: isMobile ? "none" : "initial" }}
//         >
//           <Link to="#">
//             <img src={signIn} alt="Sign in" className={styles.signIn} />
//           </Link>

//           <Link to="#">
//             <img src={demo} alt="Watch demo" className={styles.watchDemo} />
//           </Link>
//         </div>
//       </Row>
//     </Content>
//   );
// };

// export default WelcomeBanner;

import React from "react";
import video from "../../Assets/video.mp4";
import { Image, Typography } from "antd";
import styles from "../Styles/welcomeBanner.module.css";
import moust from "../../Assets/Group 1500.png";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { homeSection } from "../../Common/Components/multilanguages";
const { Text } = Typography;
const WelcomeBanner = () => {
  const { defaultLanguage } = useSelector((state) => state.navbarReducer);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  return (
    <div style={{ position: "relative" }}>
      <video autoPlay muted width={"100%"} loop>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={styles.overlay}>
        <Text style={{ display: "block" }} className={styles.maintext}>
          {homeSection["firstSection"][defaultLanguage]}
        </Text>
        <div style={{ position: "relative" }}>
          <Text style={{ display: "block" }} className={styles.nexttext}>
            Handover your paws at lovable & safe hands.
          </Text>
          <div
            style={{
              position: "absolute",
              left: -25,
              top: 25,
              display: isMobile ? "none" : "",
            }}
          >
            <Image src={moust} width={25} />
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <button className={styles.appointmentbtn}>Book Appointment</button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBanner;
