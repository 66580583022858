import React, { useEffect, useState } from "react";
import { Button, Dropdown, Image, Layout, Menu, Select, Space } from "antd";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import Logo from "../Assets/Navbar/Logo.png";
// import LoginSignup from "../Assets/Navbar/login_signup.png";
import Logo from "../Assets/Frame 32 (1).png";
import LoginSignup from "../Assets/Group 3.png";
import styles from "./Styles/header.module.css";
import logoStyles from "./Styles/logo.module.css";
import { useMediaQuery } from "react-responsive";
import { language } from "../Common/Components/multilanguages";
import { navbarActions } from "./state/actions";
const { Header } = Layout;

function Navbar() {
  const dispatch = useDispatch();
  const { defaultLanguage } = useSelector((state) => state.navbarReducer);
  console.log(defaultLanguage);
  const isMobile = useMediaQuery({ maxWidth: 890 });

  const items = [
    {
      label: (
        <a href="/#onBoard" rel="noopener noreferrer">
          Onboard
        </a>
      ),
      key: "onboard",
    },
    {
      type: "divider",
      dashed: true,
    },
    {
      label: (
        <a href="/#partner" rel="noopener noreferrer">
          Partner
        </a>
      ),
      key: "Partner",
    },
    {
      type: "divider",
      dashed: true,
    },
    {
      label: (
        <a href="/#services" rel="noopener noreferrer">
          Our services
        </a>
      ),
      key: "services",
    },
    {
      type: "divider",
      dashed: true,
    },
    {
      label: (
        <a href="/#Reservation" rel="noopener noreferrer">
          Reservation policy
        </a>
      ),
      key: "reservation",
    },
    {
      type: "divider",
      dashed: true,
    },
    {
      label: (
        <a href="/#getInTouch" rel="noopener noreferrer">
          Get in touch
        </a>
      ),
      key: "getintouch",
    },
    isMobile
      ? {
          type: "divider",
          dashed: true,
        }
      : null,
    isMobile
      ? {
          label: (
            <a href="/#" rel="noopener noreferrer">
              Log in/Sign in
            </a>
          ),
          key: "loginsignin",
        }
      : null,
  ];
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let handleChange = (languageArgument) => {
    console.log("languageArgument", languageArgument);
    dispatch(navbarActions.changeLanguage(languageArgument));
  };

  return (
    <Header
      className={`${styles.headerStyle} ${scrolled ? styles.scrolled : ""}`}
    >
      <div className={logoStyles.LogoAlignment}>
        <Link to="/">
          <Image
            preview={false}
            className={styles.logo}
            src={Logo}
            alt="Logo"
          />
        </Link>
      </div>

      <Menu
        theme="light"
        mode="horizontal"
        // onClick={onClick}
        // selectedKeys={[current]}
        style={{ backgroundColor: "transparent" }}
        items={items}
        className={styles.menu}
        overflowedIndicator={<MenuOutlined />}
      />

      <>
        {/* language selection */}

        <Select
          defaultValue="English"
          style={{
            width: 120,
          }}
          onChange={handleChange}
          options={language}
        />
      </>

      <div
        className={logoStyles.LogoAlignmentRight}
        style={{ display: isMobile ? "none" : "initial" }}
      >
        <Link to="#">
          <Image
            src={LoginSignup}
            preview={false}
            className={styles.logo}
            alt="Login_signup"
          />
        </Link>
        {/* <Button style={{height:40,width:150,fontSize:"1.2em"}}>Get Started</Button> */}
      </div>
    </Header>
  );
}
export { Navbar };
