import React from "react";
import PetCard from "./Components/PetCard";
import Persian from "../Assets/Persian.png";
import German from "../Assets/german.png";
import labrador from "../Assets/LabradorRetriever.png";
import himalayan from "../Assets/himalayancat.png";
import golden from "../Assets/Golden.png";
import styles from "./Styles/Knowthem.module.css";
import { Col, Row } from "antd";

const Knowthem = () => {
  const pets = [
    {
      name: "Dog-German Sheperd",
      desc: "German Sheperds as breeds are considered to be one of the most intelligent.",
      type: "They are smart and intuitive.",
      characteristics: "Loyal, Obedient,Watchful",
      age: "9-13years",
      group: "Herding",
      image: German,
    },
    {
      name: "Cat- Persian",
      desc: "They are also known as Persian Longhair & Iranian.",
      type: "The origin of Persian cats is from Iran.",
      characteristics: "Lazy, like to relax all time.",
      age: " 10 to 15 years.",
      group: "Biting",
      image: Persian,
    },
    {
      name: "Dog-Labrador Retriever",
      desc: "They are smart,loving & make a great family dog",
      type: "Preferred choice for Indians.",
      characteristics: "Agile, Gentle,Kind, Intelligent",
      age: "10 to 14 years",
      group: "Sporting",
      image: labrador,
    },
    {
      name: "Cat-Himalayan",
      desc: "These are quite famous species in India.",
      type: " It is also known as Himmie & Colourpoint Persian Cat.",
      characteristics: "Loyal & loving",
      age: "12 to 15 years",
      group: "Sweet & Sporting",
      image: himalayan,
    },
    {
      name: "Dog-Golden Retriever",
      desc: "This is another popular dog breed in the list of most popular dog breeds in India.",
      type: "",
      characteristics: "Friendly,Reliable,Kind, Intelligent",
      age: "10 to 12 years",
      group: "Sporting",
      image: golden,
    },
  ];
  return (
    <div id="knowThem" className={styles.entireKnowthem}>
      {/* <img src={title} alt="Know them" className={styles.title} /> */}
      <Row className={styles.allcards}>
        {pets?.map((pet) => (
          <Col key={pet?.name}>
            <PetCard pet={pet} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Knowthem;
