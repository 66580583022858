import axios from "axios";

// const apiURL = process.env.REACT_APP_BASE_URL;
// const apiURL = process.env.REACT_APP_BACKEND_URL;

// Post data
const addDataRequest = (data, role) => {
  // let token = localStorage.getItem("auth-token");
  console.log(data);

  const config = {
    headers: {
      accept: "application/json",
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json",
    },
  };

  let url;

  if (role) {
    url = `http://3.111.246.77:8080/v1/getInTouch/${role}`;
  } else {
    url = "http://3.111.246.77:8080/v1/getInTouch/user";
  }

  return axios.post(url, data, config);
};

export const commonApi = {
  addDataRequest,
};
