import React from "react";
import { Col, Row, Typography, Button } from "antd";
import { useMediaQuery } from "react-responsive";
import { v4 as uuidv4 } from "uuid";
import styles from "./../Styles/blog.module.css";

const { Text, Paragraph } = Typography;

const BigBlogCard = ({ blogCards, handleClick }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return !isMobile ? (
    <div style={{ position: "relative" }}>
      <>
        <Row
          style={{
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: "white",
          }}
          gutter={[16, 16]}
        >
          <Col md={17} lg={17} xl={17} xxl={17}>
            <div className={styles.mainSection}>
              <div
                className={styles.imageContainer}
                style={{ float: "left", marginRight: "20px" }}
              >
                <img
                  style={{
                    borderBottomLeftRadius: "5px",
                    display: "inline",
                  }}
                  className={styles.mainImage}
                  // preview={false}
                  src={blogCards[0]?.image}
                  alt="Dog"
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "5px",
                  paddingRight: "5px",
                }}
              >
                <Text className={styles.petNews}>Pet News</Text>
                <Text className={styles.date}>22-01-2024</Text>
              </div>
              <div className={styles.textContainer}>
                <h1 className={styles.blogTitle}>{blogCards[0]?.title}</h1>
                <Text className={styles.blogdesc}>
                  {blogCards[0]?.description}
                </Text>
              </div>
            </div>
          </Col>
          <Col md={6} lg={6} xl={6} xxl={6}>
            <Row gutter={[16, 16]}>
              <Col md={24} lg={24} xl={24} xxl={24}>
                <div className={styles.latestUpdates}>
                  <Text className={styles.latest}>Latest updates</Text>
                </div>
              </Col>
              <div className={styles.blogScroll}>
                {blogCards?.slice(1).map((blog) => (
                  <Col md={24} lg={24} xl={24} xxl={24} key={uuidv4()}>
                    <div
                      style={{
                        borderRadius: "15px",
                        marginBottom: "5px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      }}
                    >
                      <div className={styles.blogImageContainer}>
                        <img
                          // preview={false}
                          src={blog?.image}
                          alt={blog?.title}
                          style={{ borderRadius: "15px" }}
                          className={styles.blogImage}
                        />
                      </div>
                      <div className={styles.dateBlog}>22-01-2024</div>

                      <div className={styles.textContent}>
                        <Paragraph className={styles.paraText}>
                          {blog?.title}
                        </Paragraph>
                      </div>
                      <div
                        style={{ textAlign: "center", paddingBottom: "10px" }}
                      >
                        <Button className={styles.readMore}>Read More</Button>
                      </div>
                    </div>
                  </Col>
                ))}
              </div>
            </Row>
          </Col>
        </Row>
      </>
    </div>
  ) : (
    <div style={{ overflowX: "auto", padding: "20px" }}>
      <Row gutter={[16, 16]}>
        {blogCards?.map((blog) => (
          <Col xs={24} sm={12} key={uuidv4()}>
            <div
              style={{
                borderRadius: "15px",
                marginBottom: "5px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <div className={styles.blogImageContainer}>
                <img
                  // preview={false}
                  src={blog?.image}
                  alt={blog?.title}
                  style={{ borderRadius: "15px" }}
                  className={styles.blogImage}
                />
              </div>
              <div className={styles.dateBlog}>22-01-2024</div>

              <div className={styles.textContent}>
                <Paragraph className={styles.paraText}>{blog?.title}</Paragraph>
              </div>
              <div style={{ textAlign: "center", paddingBottom: "10px" }}>
                <Button className={styles.readMore}>Read More</Button>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BigBlogCard;
