import { appconstants } from "./action-types";

const initialState = {
  contactUsData: [],
  contactUsDataLoading: true,
  contactUsDataError: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case appconstants.ADD_CONTACT_DATA_SUCCESS:
      return {
        ...state,
        contactUsData: action.contactUsData,
        contactUsDataLoading: true,
        contactUsDataError: null,
      };
    case appconstants.ADD_CONTACT_DATA_FAILURE:
      return {
        ...state,
        contactUsData: [],
        contactUsDataLoading: false,
        contactUsDataError: action.homeDataError,
      };

    default:
      return state;
  }
};
export { appReducer };
