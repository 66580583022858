import { navbarconstants } from "./action-types";

const changeLanguage = (data) => {
  return {
    type: navbarconstants.CHANGE_LANGUAGE,
    defaultLanguage: data,
  };
};

export const navbarActions = {
  changeLanguage,
};
