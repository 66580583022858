import React from "react";
import { Card } from "antd";
import styles from "../Styles/Knowthem.module.css";

const PetCard = ({ pet }) => {
  return (
    <>
      <Card
        className={styles.cardStyle}
        cover={
          <div className={styles.imagebg}>
            <img className={styles.imageStyle} src={pet?.image} alt="Cat" />
          </div>
        }
        bodyStyle={{
          backgroundColor: "#315D80",
          padding: 0,
          fontFamily: "Poppins,sans-serif",
          color: "white",
        }}
      >
        <div className={styles.bodyStyle}>
          <div className={styles.petName}>{pet?.name}</div>
          <div className={styles.text}>{pet?.desc}</div>
          <div className={styles.text}>{pet?.type}</div>
          <div className={styles.text}>
            Characteristics-{pet?.characteristics}
          </div>
          <div className={styles.text}>Age- {pet?.age}</div>
          <div className={styles.text}>Group-{pet?.group}</div>
        </div>
      </Card>
    </>
  );
};

export default PetCard;
