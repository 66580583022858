import React from "react";
import { Row, Col, Image, Layout, Typography } from "antd";
import styles from "../Styles/serviceDetailsCarousel.module.css";
const { Text } = Typography;
const { Content } = Layout;

const SlideServiceDetails = ({
  heading,
  title,
  description,
  image,
  button,
}) => {
  return (
    <Content>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col lg={1} xl={1} xxl={1}></Col>
        <Col sm={24} md={24} lg={11} xl={11} xxl={11}>
          <div className={styles.imageContainer}>
            <Image
              preview={false}
              src={image}
              alt={title}
              // width={isMediumscreen ? 100 : 150}
              className={styles.carouselImage}
            />
          </div>
        </Col>
        <Col sm={24} md={24} lg={11} xl={11} xxl={11}>
          <div className={styles.textContainer}>
            <Text
              style={{ display: "block", textAlign: "center" }}
              className={styles.heading}
            >
              {heading}
            </Text>
            <div className={styles.titleContainer}>
              <Text
                style={{ display: "block", textAlign: "center" }}
                className={styles.title}
              >
                {title}
              </Text>
            </div>
            <div className={styles.descriptionContainer}>
              <Text
                style={{ display: "block", textAlign: "center" }}
                className={styles.description}
              >
                {description}
              </Text>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button style={{ textAlign: "center" }}>{button}</button>
          </div>
        </Col>
        <Col lg={1} xl={1} xxl={1}></Col>
      </Row>
    </Content>
  );
};

export default SlideServiceDetails;
