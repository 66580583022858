import React from "react";
import mobilelaptop from "../../Assets/mobilelaptop.png";
import { Col, Image, Row, Typography } from "antd";
import styles from '../Styles/mobilelaptop.module.css';
const { Text } = Typography;
const MobileLaptop = () => {
    return (
        <div>
            <Row gutter={[16, 16]} justify={"center"} style={{ alignItems: "center", marginTop: "3em", marginBottom: "3em" }}>
                <Col xs={20} sm={20} md={12} lg={12} xl={12}>
                    <Image src={mobilelaptop} alt="mobile" preview={false} />
                </Col>
                <Col xs={20} sm={20} md={10} lg={10} xl={10}>
                    <Text className={styles.title}>
                        Access our services seamlessly across multiple platforms!
                    </Text>
                    <Text className={styles.paragraph}>
                        Whether you are on your desktop, Android device, or iPhone, our
                        website and dedicated mobile apps provide easy access to all our
                        features. Enjoy a consistent experience no matter where you are or
                        what device you're using. Stay connected and make the most of our
                        offerings with our versatile accessibility options. Download our app
                        or visit our website today to get started!
                    </Text>
                </Col>
                <div className={styles.btn} >
                    <button className={styles.partnerbtn}>Explore Now</button>
                </div>
            </Row>

        </div>
    );
};

export default MobileLaptop;
