import { Col, Form, Input, Row, message } from "antd";
import React from "react";
import PhoneInput from "react-phone-input-2";
import styles from "./Styles/GetinTouch.module.css";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { appActions } from "../state/actions";
const { TextArea } = Input;
const GetInTouch = () => {
  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const handleFinish = (values) => {
    dispatch(
      appActions.AddContactUsModalData({
        ...values,
        phoneNumber: values.phoneNumber.slice(2),
        countryCode: values.phoneNumber.slice(0, 2),
      })
    );

    messageApi.open({
      type: "success",
      content: "Thank you for reaching out to us",
    });
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <>
      {contextHolder}
      <div id="getInTouch" className={styles.getInTouchWrapper}>
        <Form
          form={form}
          style={{
            textAlign: "center",
            padding: "0.05px",
          }}
          onFinish={handleFinish}
          className={styles.getInTouchForm}
        >
          <h1 className={styles.getIntouchTitle}>Get in Touch</h1>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={20} sm={11} md={10} lg={10}>
              <Form.Item rules={[{ required: true }]} name="name">
                <Input placeholder="Name" className={styles.formInput} />
              </Form.Item>
            </Col>
            <Col xs={20} sm={11} md={10} lg={10}>
              <Form.Item name="phoneNumber">
                <PhoneInput
                  countryCodeEditable={false}
                  country="in"
                  inputStyle={{
                    width: "100%",
                    padding: "8px 11px 8px 48px",
                    border: "none",
                    height: "45.45px",
                    borderRadius: "2px",
                  }}
                  buttonStyle={{
                    border: "0.5px solid rgba(236, 236, 236, 0.5)",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={20} sm={11} md={10} lg={10}>
              <Form.Item
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
                name="email"
              >
                <Input placeholder="Email" className={styles.formInput} />
              </Form.Item>
            </Col>
            <Col xs={20} sm={11} md={10} lg={10}>
              <Form.Item name="city" rules={[{ required: true }]}>
                <Input placeholder="City" className={styles.formInput} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={20} sm={22} md={20} lg={20}>
              <Form.Item name="message">
                <TextArea
                  placeholder="Message"
                  autoSize={{ minRows: 5, maxRows: 6 }}
                  className={styles.formInput}
                />
              </Form.Item>
            </Col>
          </Row>
          <button className={styles.submitbtn} type="submit">
            Submit
          </button>
        </Form>
      </div>
    </>
  );
};

export default GetInTouch;
