import React from "react";
import { Image, Typography } from "antd";
import styles from "../Styles/petKnowledge.module.css";

const { Text } = Typography;

const PetKnowledgeSlide = ({ title, image }) => {
  return (
    <div className={styles.carouselWrapper}>
      <div className={styles.imageWrapper}>
        <Image
          src={image}
          preview={false}
          alt={title}
          className={styles.image}
        />
      </div>
      <div className={styles.textContainer}>
        <Text className={styles.title}>{title}</Text>
      </div>
    </div>
  );
};

export default PetKnowledgeSlide;
