import React from "react";
import partner from "../../Assets/SPA.png";
import { Image } from "antd";
import styles from "../Styles/partnerbanner.module.css";

const PartnerBanner = () => {
  return (
    <div style={{ position: "relative" }}>
      <Image src={partner} preview={false} />
      <div className={styles.btn} >
        <button className={styles.partnerbtn}>Be a Partner</button>
      </div>
    </div>
  );
};

export default PartnerBanner;
