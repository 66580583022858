import React from "react";
import { Col, Image, Layout, Row, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import icon1 from "../../Assets/PrivacyReservation/icon1.png";
import icon2 from "../../Assets/PrivacyReservation/icon2.png";
import icon3 from "../../Assets/PrivacyReservation/icon3.png";
import styles from "../Styles/privacyReservation.module.css";

const { Content } = Layout;
const { Title, Text } = Typography;

const content = [
  {
    image: icon1,
    title: "Privacy policy",
    description:
      "Our pet service website respects your privacy. We collect only necessary personal information for booking and communication purposes. Your data is securely stored and never shared with third parties without consent. We use cookies for a better user experience. By using our site, you agree to our privacy policy.",
  },
  {
    image: icon2,
    title: "Reservation policy",
    description:
      "If there’s a cancellation from a pet stay, we provide full support from our end. Every booking made by you through Hey home includes reservation protection. We will arrange and help you find an alternative, if you don’t want an alternative a complete refund of your amount will be given.",
  },
  {
    image: icon3,
    title: "Disclaimer",
    description:
      "Our pet service website provides general information and advice. We are not veterinarians, and our content does not substitute professional veterinary guidance. While we strive for accuracy, we are not liable for any actions taken based on our information. Always consult a qualified veterinarian for your pet's specific needs.",
  },
];

const PrivacyReservation = () => {
  return (
    <Content className={styles.privacyContent} id="Reservation">
      <section className={styles.mainPart}>
        <Row className={styles.mainTextContainer}>
          <Col span={24}>
            <Title className={styles.title} style={{ display: "block" }}>
              We prioritize pet safety, health, and happiness
            </Title>
          </Col>

          <Col span={24}>
            <Text
              style={{ display: "block", textAlign: "center" }}
              className={styles.text}
            >
              Our services adhere to strict standards of care, professionalism,
              and accountability.
            </Text>
          </Col>

          <Col span={24}>
            <Text
              style={{ display: "block", textAlign: "center" }}
              className={styles.text}
            >
              Your pet's well-being is our top priority.
            </Text>
          </Col>
        </Row>

        <Row
          gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
          style={{ padding: "25px" }}
        >
          {content?.map((data) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} key={uuidv4()}>
              <div className={styles.card}>
                <div className={styles.iconSet}>
                  <Image
                    src={data?.image}
                    preview={false}
                    alt={data?.title}
                    className={styles.icon}
                  />
                  <Text className={styles.iconText}>{data?.title}</Text>
                </div>

                <div className={styles.descriptionContainer}>
                  <Text
                    style={{ display: "block" }}
                    className={styles.description}
                  >
                    {data?.description}
                  </Text>
                </div>
              </div>
            </Col>
          ))}
          <Col lg={8}></Col>
          <Col lg={8}></Col>
        </Row>
      </section>
    </Content>
  );
};

export default PrivacyReservation;
